.services {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 3;
  .services-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 40px;
    h2 {
      margin: 0px 0px 15px;
      font-size: 42px;
      color: var(--color-grey1);
    }
    p {
      margin: 0px 0px 10px;
      color: var(--color-grey2);
    }
  }
  .services-items {
    flex: 2;
    color: var(--color-grey1);
    gap: 50px;
    display: flex;
    justify-content: flex-end;
    .services-item {
      max-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background-color: var(--color-white);
      padding: 20px 10px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
        0px 4px 8px rgba(0, 0, 0, 0.06);
      cursor: pointer;

      .services-item-icon {
        height: 50px;
        width: 50px;
      }
      h3 {
        margin: 15px 0px;
      }
      p {
        margin: 0px;
        color: var(--color-grey2);
        text-align: center;
      }
    }
  }
}

@media (max-width: 820px) {
  .services {
    display: block;

    .services-info {
      padding-bottom: var(--padding-regular);
    }

    .services-items {
      flex: 2;
      color: var(--color-grey1);
      gap: 50px;
      display: flex;
      justify-content: center;
      .services-item {
        max-width: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: var(--color-white);
        padding: 20px 10px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
          0px 4px 8px rgba(0, 0, 0, 0.06);
        .services-item-icon {
          height: 50px;
          width: 50px;
        }
        h3 {
          margin: 15px 0px;
        }
        p {
          margin: 0px;
          color: var(--color-grey2);
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .services {
    .services-items {
      flex: 2;
      color: var(--color-grey1);
      gap: 50px;
      display: block;
      justify-content: flex-end;
      .services-item {
        max-width: unset;
        margin-bottom: var(--padding-regular);
        .services-item-icon {
          height: 50px;
          width: 50px;
        }
        h3 {
          margin: 15px 0px;
        }
        p {
          margin: 0px;
          color: var(--color-grey2);
          text-align: center;
        }
      }
    }
  }
}