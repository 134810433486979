.shortcutCountryList {
  display: flex;
  flex-direction: column;
  padding-top: var(--padding-regular);

  .info {
    display: flex;
    align-items: center;
    flex-direction: column;

    h2 {
      font-size: 48px;
      margin: 0;
      line-height: 130%;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      max-width: 750px;
      color: var(--color-grey2);
    }
  }

  .regionWrap {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 50px;
    margin-top: 20px;

    .regionsContainer {
      border-radius: var(--border-radius-small);
      margin: 5px;
      display: flex;
      justify-content: space-between;

      .regions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 14px;
        background-color: #fbfbfc;
        border-radius: 8px;
        border: solid 1px #d8d8d87a;
        box-shadow: var(--box-shadow);
        margin-bottom: 14px;

        h3 {
          margin: 5px;
          color: #565656;
        }

        .countries {
          span {
            margin-right: 5px;
          }

          a {
            &:hover {
              color: var(--color-cta);
            }
          }

          .countriesMore {
            padding: 0px 10px;
            color: #70a6ea;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }

      /** Si el número de regiones es impar podemos centrar el ultimo elemento (last-child) con esto ------------------------------------------ */

      // &:last-child {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   grid-column: span 2;

      //   .regions {
      //     width: 50%;
      //   }
      // }
    }
  }
}

@media (max-width: 820px) {
  .shortcutCountryList {
    display: flex;
    flex-direction: column;
    padding-top: var(--padding-regular);

    .info {
      display: block;
      text-align: center;

      h2 {
        font-size: 30px;
      }
    }

    .regionWrap {
      grid-template-columns: repeat(1, auto);
      grid-column-gap: 0;
    }
  }
}


@media (max-width: 576px) {
  .shortcutCountryList {
    .regionWrap {
      .regionsContainer {
        .regions {
          display: block;

          h3 {
            margin-left: 0px;
          }

          .countries {
            span {
              padding: var(--padding-small) 0;
            }

            .countriesMore {
              display: block;
              text-align: right;
              padding: var(--padding-regular) 0 0 0;
            }
          }
        }

      }
    }
  }
}