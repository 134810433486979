.hero {
  display: flex;
  height: 350px;
  justify-content: center;
  align-items: center;
  .contentLeft {
    width: 100%;
    z-index: 2;
    .buttonWrapper {
      margin: 20px 0 10px;
    }
    h1, h2 {
      width: 120%;
      color: var(--color-grey1);
      font-size: 48px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: 0.025em;
      margin: 0 0 1rem 0;
    }
    p {
      max-width: 500px;
      color: var(--color-grey2);
      font-size: 18px;
    }
  }
  .contentRight {
    width: 100%;
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    margin-top: 10px;
    .image-container {
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 1;
      img {
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 820px) {
  .hero {
    .contentLeft {
      width: 100%;
      z-index: 2;
      padding: 0 var(--padding-regular) 0 0;
      h1 {
        width: 120%;
        font-size: 40px;
        font-weight: 600;
        line-height: 40px;
      }
    }
  }
}

@media (max-width: 576px) {
  .hero {
    display: grid;
    grid-row: 2;
    height: auto;
    .contentLeft {
      width: 100%;
      h1 {
        width: 100%;
        font-size: 45px;
        font-weight: 600;
        line-height: 45px;
      }

    }
    .contentRight {
      height: 75vw;
    }
  }
}